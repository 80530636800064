import {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from "react";
import { useRouter } from "next/router";
import { CognitoUser } from "amazon-cognito-identity-js";
import { Auth } from "aws-amplify";
import * as Sentry from "@sentry/nextjs";
import { klayAction } from "utils/klay";
import { getClientByAuthId, setSignedIn } from "queries/clients";
import request from "graphql-request";
import { capitalize, getPrices } from "pages/enroll";

export interface User extends CognitoUser {
  attributes: {
    [key: string]: any;
  };
}

interface State {
  loading: boolean;
  user?: User;
  type?: string;
  claims?: { [key: string]: string };
  token?: string;
  profile?: any;
  selectedCourses: any[];
  userData: any;
  sourceParams?: { [key: string]: string };
}

interface SessionContextValue extends State {
  signIn: ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => Promise<any>;
  signUp: ({
    email,
    password,
    first_name,
    last_name,
    phone,
  }: {
    email: string;
    password: string;
    first_name: string;
    last_name: string;
    phone: string;
  }) => Promise<any>;
  logout: () => Promise<void>;
  setContext: React.Dispatch<React.SetStateAction<State>>;
  addCourse: (data: any) => void;
  removeCourse: (course: any) => void;
  empty: () => void;
  currentUser: () => void;
}

const initialState = {
  loading: true,
  user: undefined,
  type: undefined,
  token: undefined,
  claims: undefined,
  selectedCourses: [],
  userData: null,
  sourceParams: {},
};

export const SessionContext = createContext(
  initialState as SessionContextValue
);

export interface API {
  children: any;
}

const getUser = async function (id: string, token: string) {
  return request(
    process.env.NEXT_PUBLIC_API_URL!,
    getClientByAuthId,
    {
      id,
    },
    {
      authorization: `Bearer ${token}`,
    }
  );
};

async function updateUser(id: string, token: string) {
  return request(
    process.env.NEXT_PUBLIC_API_URL!,
    setSignedIn,
    {
      id,
    },
    {
      authorization: `Bearer ${token}`,
    }
  );
}

export const Session = ({ children }: API) => {
  const [state, setState] = useState<State>(initialState);
  const router = useRouter();

  const currentUser = useCallback(() => {
    Auth.currentAuthenticatedUser({
      bypassCache: true,
    })
      .then(async (data) => {
        const { idToken } = data.signInUserSession;
        const claims = idToken.payload;
        const token = idToken.jwtToken;

        if (data && data.attributes) {
          try {
            // @ts-ignore
            if (window.clarity && data.attributes.email) {
              // @ts-ignore
              window.clarity("identify", data.attributes.email);
            }
          } catch (err) {
            console.log(err)
          }

          try {
            Sentry.configureScope(function (scope) {
              scope.setUser({
                id: data.attributes.sub,
                email: data.attributes.email,
              });
            });
          } catch (err) {
            console.log(err);
          }

          try {
            // @ts-ignore
            klaviyo &&
              // @ts-ignore
              klaviyo.push([
                "identify",
                {
                  $id: data.attributes.sub,
                  $email: data.attributes.email,
                  $first_name: data.attributes.given_name,
                  $last_name: data.attributes.family_name,
                },
              ]);
          } catch (err) {
            console.log(err);
          }

          // @ts-ignore
          window.Intercom &&
            // @ts-ignore
            window.Intercom("boot", {
              app_id: "ha8vli9e",
              email: data.attributes.email,
              name: `${data.attributes.given_name} ${data.attributes.family_name}`,
              user_id: data.attributes.sub,
            });

          window.gtag && window.gtag("set", { user_id: data.attributes.sub });

          (window as any).heap &&
            (window as any).heap.identify(data.attributes.sub);

          (window as any).heap &&
            (window as any).heap.addUserProperties({
              email: data.attributes.email,
              name: `${data.attributes.given_name} ${data.attributes.family_name}`,
            });
        }

        const courses = sessionStorage.getItem("cc.courses");

        setState((s) => ({
          ...s,
          loading: false,
          user: data as User,
          claims: {
            ...claims,
            ...JSON.parse(claims["https://hasura.io/jwt/claims"]),
          },
          token,
          selectedCourses: courses ? JSON.parse(courses) : [],
        }));

        const result = await getUser(claims.sub, token);

        await updateUser(claims.sub, token);

        setState((s) => ({
          ...s,
          // @ts-ignore
          userData: result.user && result.user[0],
        }));
      })
      .catch(() => {
        const courses = sessionStorage.getItem("cc.courses");
        setState({
          ...state,
          loading: false,
          selectedCourses: courses ? JSON.parse(courses) : [],
        });
      });
  }, [state]);

  useEffect(() => {
    const courses = sessionStorage.getItem("cc.courses");
    if (courses) {
      setState((s) => ({
        ...s,
        selectedCourses: JSON.parse(courses),
      }));
      currentUser();
    } else {
      currentUser();
    }

    if (window && window.location.search && window.location.search.length > 0) {
      const urlParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlParams);
      try {
        localStorage.setItem("cc.p", JSON.stringify(params));
        setState((s) => ({ ...s, sourceParams: params }));
      } catch (err) {
        console.log(err);
      }
    } else {
      try {
        const d = localStorage.getItem("cc.p");
        const e = JSON.parse(d);
        setState((s) => ({ ...s, sourceParams: e }));
      } catch (err) {
        console.log(err);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logout = useCallback(async () => {
    try {
      await Auth.signOut();
      Sentry.configureScope((scope) => scope.setUser(null));
      setState(initialState);
      router.replace("/");
    } catch (err) {
      Sentry.captureException(err);
    }
  }, [router]);

  const signIn = useCallback(
    async ({ email, password }: { email: string; password: string }) => {
      try {
        const signInResult = await Auth.signIn({
          username: email,
          password,
        });
        currentUser();
        return signInResult;
      } catch (err) {
        Sentry.captureException(err);
        return false;
      }
    },
    [currentUser]
  );

  const signUp = useCallback(
    async ({
      email,
      password,
      first_name,
      last_name,
      phone,
    }: {
      email: string;
      password: string;
      first_name: string;
      last_name: string;
      phone: string;
    }) => {
      try {
        const { user } = await Auth.signUp({
          username: email,
          password,
          attributes: {
            email,
            given_name: first_name,
            family_name: last_name,
            phone_number: `+44${phone.replace(/^0+/, "").replace(/ /g, "")}`,
          },
        });
        const signIn = await Auth.signIn({
          username: email,
          password,
        });
        currentUser();
        return signIn;
      } catch (err) {
        Sentry.captureException(err);
        throw new Error(err.message || err);
      }
    },
    [currentUser]
  );

  const trackAdd = (selectedCourses, data) => {
    if (data.selectedEvent) {
      const payload = {
        AddedItemProductName: data.fields.title,
        AddedItemProductID: data.sys.id,
        AddedItemCategories: data.fields.categories.map((x) =>
          x
            .replace(/-/g, " ")
            .replace(/(^\w{1})|(\s{1}\w{1})/g, (match) => match.toUpperCase())
        ),
        AddedItemImageURL: `https:${data.fields.listingImage.fields.file.url}`,
        AddedItemURL: window.location.href,
        AddedItemPrice: data.selectedEvent.price / 100,
        Items: selectedCourses.map((x) => klayAction(data)),
      };

      try {
        // @ts-ignore
        klaviyo && klaviyo.push(["track", "Added Course", payload]);
      } catch (err) {
        console.log(err);
        Sentry.captureException(err);
      }

      try {
        const t = getPrices({ courses: selectedCourses });
        window.gtag &&
          window.gtag("event", "add_to_cart", {
            currency: "GBP",
            value: t.total / 100,
            items: selectedCourses.map((x, i) => {
              return {
                item_id: x.package
                  ? x.event.id
                  : x.selectedEvent && x.selectedEvent.id
                  ? x.selectedEvent.id
                  : "No Event Id",
                item_name: x.fields.title,
                affiliation: "Cosmetic College",
                currency: "GBP",
                index: i,
                item_brand: "Cosmetic College",
                item_category: "Courses",
                item_category2: x.fields.categories[0],
                item_list_id: x.fields.categories[0],
                item_list_name: capitalize(x.fields.categories[0]),
                price: x.package
                  ? x.event.standardPrice / 100
                  : x.selectedEvent && x.selectedEvent.price
                  ? x.selectedEvent.price / 100
                  : 0,
                quantity: 1,
              };
            }),
          });

        (window as any).fbq &&
          (window as any).fbq("track", "AddToCart", {
            value: data.event.standardPrice / 100,
            currency: "GBP",
            content_name: data.fields.title,
            content_type: "product", // Required for Dynamic Product Ads
            content_ids: data.event.id,
          });
      } catch (err) {
        console.log(err);
        Sentry.captureException(err);
      }
    } else {
      (window as any).fbq &&
        (window as any).fbq("track", "AddToCart", {
          value: data.event.standardPrice / 100,
          currency: "GBP",
          content_name: data.fields.title,
          content_type: "product", // Required for Dynamic Product Ads
          content_ids: data.event.id,
        });
    }
  };

  const addCourse = useCallback(
    (data) => {
      const b = Array.isArray(data) ? data : [data];
      const selectedCourses = [...state.selectedCourses, ...b];

      setState((s) => ({
        ...s,
        selectedCourses,
      }));

      sessionStorage.setItem("cc.courses", JSON.stringify(selectedCourses));

      b.forEach((x) => {
        trackAdd(selectedCourses, x);
      });
    },
    [state]
  );

  const removeCourse = useCallback(
    (course) => {
      const courses = [...state.selectedCourses];
      if (courses.length === 1) {
        setState((s) => ({
          ...s,
          selectedCourses: [],
        }));
        return sessionStorage.setItem("cc.courses", JSON.stringify([]));
      }

      const f = courses.findIndex((x) => x.event.id === course);
      if (f > -1) {
        courses.splice(f, 1);
        setState((s) => ({
          ...state,
          selectedCourses: courses,
        }));
        sessionStorage.setItem("cc.courses", JSON.stringify(courses));
      }
    },
    [state]
  );

  const empty = useCallback(() => {
    setState((s) => ({
      ...s,
      selectedCourses: [],
    }));
  }, [state]);

  const value: SessionContextValue = {
    ...state,
    logout,
    signIn,
    signUp,
    currentUser,
    setContext: setState,
    addCourse,
    removeCourse,
    empty,
  };

  return (
    <SessionContext.Provider value={value}>{children}</SessionContext.Provider>
  );
};

export const SessionProvider = Session;

export const useSession = () => useContext(SessionContext);
